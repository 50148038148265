export const CHECK_AUTH = "checkAuth";
export const FETCH_ARTICLE = "fetchArticle";
export const FETCH_COMMENTS = "fetchComments";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const FETCH_APPOINTMENT_DETAILS = "appointmentDetails"
export const FETCH_COMPANY_DETAILS = "companyDetails"
export const FETCH_STAFF_LIST = "activeStaffList"
export const FETCH_STAFF_DETAILS = "staffDetails"
export const FETCH_SERVICES_LIST = "servicesList"
export const FETCH_COMMENTS_PAGES_COUNT = "commentPages"
export const POST_COMMENT = "postComment"
