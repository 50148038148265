import Vue from "vue";
import VueRouter from "vue-router";
import Comments from "@/views/organization/Comments";
import PostComment from "@/views/organization/PostComment";
import OrganizationHeader from "@/views/organization/shared/OrganizationHeader";
import NotFound from "@/views/NotFound";
import ThanksForComment from "@/views/organization/ThanksForComment";
import Organization from "@/views/organization/Organization";
// import Organization from "@/views/components/Carouse";
import Footer from "@/views/organization/shared/OrganizationFooter";
import Services from "@/views/organization/Services";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    linkExactActiveClass: "active",
    routes: [
        {
            path: "/rate/:staffId",
            name: "comments",
            components: {
                header: OrganizationHeader,
                default: Comments,
                footer: Footer
            },
            props: { default: true }
        },
        {
            path: '/post/:postKey',
            name: "post",
            components: {
                header: OrganizationHeader,
                default: PostComment,
                footer: Footer,
            },
            props: { default: true }
        },
        {
            path: '/services',
            name: "services",
            components: {
                header: OrganizationHeader,
                default: Services,
                footer: Footer,
            },
        },
        {
            path: '/',
            name: "company",
            components: {
                header: OrganizationHeader,
                default: Organization,
                footer: Footer,
            },
            props: { default: true }
        },
        {
            path: '/thanks',
            name: "thanks",
            components: {
                header: OrganizationHeader,
                default: ThanksForComment,
                footer: Footer,
            }
        },
        {
            path: "/404",
            name: "notfound",
            components: {
                header: OrganizationHeader,
                default: NotFound,
                footer: Footer
            }
        },
        {
            path: '*',
            redirect: '/404'
        },
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
