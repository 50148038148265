import Vue from "vue";
import VueRouter from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Comments from "@/views/organization/Comments";
import Components from "./views/Components.vue";
// import Landing from "./views/components/Landing.vue";
// import Login from "./views/Login.vue";
// import Register from "./views/Register.vue";
import Profile from "./views/Profile.vue";
import PostComment from "@/views/organization/PostComment";
import OrganizationHeader from "@/views/organization/shared/OrganizationHeader";
import NotFound from "@/views/NotFound";
import ThanksForComment from "@/views/organization/ThanksForComment";
// import OrganizationFooter from "@/views/organization/shared/OrganizationFooter";
import Organization from "@/views/organization/Organization";
import NavigationLanding from "@/views/landing/NavigationLanding";
import LandingMain from "@/views/landing/IssistLanding";
import Footer from "@/views/landing/IssistFooter";
import OrgFooter from "@/views/organization/shared/OrganizationFooter";
import Services from "@/views/organization/Services";
import PrivacyPolicy from "@/views/terms/PrivacyPolicy";
import PrivacyPolicyUK from "@/views/termsua/PrivacyPolicy";
import CookiePolicy from "@/views/terms/CookiePolicy";
import Disclaimer from "@/views/terms/Disclaimer";
import TermsAndConditions from "@/views/terms/TermsAndConditions";
import EULA from "@/views/terms/EULA";
import ReturnAndRefundPolicy from "@/views/terms/ReturnAndRefundPolicy";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    linkExactActiveClass: "active",
    routes: [
        {
            path: "/components",
            name: "components",
            components: {
                header: AppHeader,
                default: Components,
                footer: AppFooter
            }
        },
        // {
        //     path: "/login",
        //     name: "login",
        //     components: {
        //         header: AppHeader,
        //         default: Login,
        //         footer: AppFooter
        //     }
        // },
        // {
        //     path: "/register",
        //     name: "register",
        //     components: {
        //         header: AppHeader,
        //         default: Register,
        //         footer: AppFooter
        //     }
        // },
        {
            path: "/company/:key/rate/:staffId",
            name: "comments",
            components: {
                header: OrganizationHeader,
                default: Comments,
                footer: OrgFooter
            },
            props: { default: true }
        },
        {
            path: "/",
            name: "landing",
            components: {
                header: NavigationLanding,
                default: LandingMain,
                footer: Footer
            }
        },

        {
            path: '/post/:postKey',
            name: "post",
            components: {
                header: OrganizationHeader,
                default: PostComment,
                footer: OrgFooter,
            },
            props: { default: true }
        },
        {
            path: '/company/:key/services',
            name: "services",
            components: {
                header: OrganizationHeader,
                default: Services,
                footer: OrgFooter,
            },
        },
        {
            path: '/company/:key',
            name: "company",
            components: {
                header: OrganizationHeader,
                default: Organization,
                footer: OrgFooter,
            },
            props: { default: true }
        },
        {
            path: '/company/:key/thanks',
            name: "thanks",
            components: {
                header: OrganizationHeader,
                default: ThanksForComment,
                footer: OrgFooter,
            }
        },
        {
            path: "/profile",
            name: "profile",
            components: {
                header: AppHeader,
                default: Profile,
                footer: AppFooter
            }
        },
        {
            path: "/privacy-policy",
            name: "Privacy Policy",
            components: {
                header: AppHeader,
                default: PrivacyPolicy,
                footer: Footer
            }
        },
        {
            path: "/privacy-policy-uk",
            name: "Privacy Policy",
            components: {
                header: AppHeader,
                default: PrivacyPolicyUK,
                footer: Footer
            }
        },
        {
            path: "/cookie-policy",
            name: "Cookie Policy",
            components: {
                header: AppHeader,
                default: CookiePolicy,
                footer: Footer
            }
        },
        {
            path: "/disclaimer",
            name: "Disclaimer",
            components: {
                header: AppHeader,
                default: Disclaimer,
                footer: Footer
            }
        },
        {
            path: "/terms-and-conditions",
            name: "Terms And Conditions",
            components: {
                header: AppHeader,
                default: TermsAndConditions,
                footer: Footer
            }
        },
        {
            path: "/eula",
            name: "EULA",
            components: {
                header: AppHeader,
                default: EULA,
                footer: Footer
            }
        },
        {
            path: "/return-refund-policy",
            name: "Return And Refund Policy",
            components: {
                header: AppHeader,
                default: ReturnAndRefundPolicy,
                footer: Footer
            }
        },
        {
            path: "/404",
            name: "notfound",
            components: {
                header: AppHeader,
                default: NotFound,
                footer: Footer
            }
        },
        {
            path: '*',
            redirect: '/404'
        },
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
