export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_COMMENT = "setComment";
export const SET_ERROR = "setError";
export const SET_APPOINTMENT_DETAILS = "APPOINTMENT_DETAILS";
export const SET_COMPANY_DETAILS = "companyDetails";
export const SET_ACTIVE_STAFF = "activeStaff";
export const SET_COMMENTS = "setComments";
export const SET_COMMENTS_COUNT = "setCommentsCount";
export const SET_STAFF = "staff";
export const SET_SERVICES = "servicesSet";
