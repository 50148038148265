import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import postComment from "./open.api.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        postComment
    }
});
