import {OpenApiService} from "@/common/api.service";
import {
    FETCH_APPOINTMENT_DETAILS,
    FETCH_COMPANY_DETAILS,
    FETCH_SERVICES_LIST,
    FETCH_STAFF_LIST,
    FETCH_STAFF_DETAILS,
    POST_COMMENT,
    FETCH_COMMENTS,
    FETCH_COMMENTS_PAGES_COUNT
} from "./actions.type";
import {
    SET_ACTIVE_STAFF,
    SET_STAFF,
    SET_APPOINTMENT_DETAILS,
    SET_COMMENT,
    SET_COMPANY_DETAILS,
    SET_SERVICES,
    SET_COMMENTS,
    SET_COMMENTS_COUNT
} from "./mutations.type";

const state = {
    errors: {},
    appointmentDetails: {
        appointmentId: String,
        appointmentName: String,
        appointmentDate: Date,
        organization: {
            orgId: String,
            logo: String,
            name: String,
            uniqueName: String,
            facebook: String,
            instagram: String,
            twitter: String,
        },
        staff: {
            name: String,
            image: String,
            color: String,
            rate: Number,
            comments: Number,
            position: String
        }
    },
    activeStaff: [],
    comments: [],
    commentPages: Number,
    staff: {
        name: String,
        image: String,
        color: String,
        rate: Number,
        comments: Number,
        position: String
    },
    services: Map,
    organization: {
        orgId: String,
        logo: String,
        name: String,
        uniqueName: String,
        facebook: String,
        instagram: String,
        twitter: String
    },
};

const getters = {
    appointmentDetails(state) {
        return state.appointmentDetails;
    },
    organization(state) {
        return state.organization;
    },
    activeStaff(state) {
        return state.activeStaff;
    },
    services(state) {
        return state.services;
    },
    staff(state) {
        return state.staff;
    },
    comments(state) {
        return state.comments;
    },
    commentPages(state) {
        return state.commentPages;
    }
};

const actions = {
    [FETCH_APPOINTMENT_DETAILS](context, key) {
        return OpenApiService.get(key)
            .then(({data}) => {
                context.commit(SET_APPOINTMENT_DETAILS, data);
            })
    },
    [FETCH_COMPANY_DETAILS](context, key) {
        return OpenApiService.getOrg(key)
            .then(({data}) => {
                context.commit(SET_COMPANY_DETAILS, data);
            })
    },
    [FETCH_STAFF_LIST](context, key) {
        return OpenApiService.getActiveStaff(key)
            .then(({data}) => {
                context.commit(SET_ACTIVE_STAFF, data);
            })
    },
    [FETCH_STAFF_DETAILS](context, key, staffId) {
        return OpenApiService.getStaff(key, staffId)
            .then(({data}) => {
                context.commit(SET_STAFF, data);
            })
    },
    [FETCH_SERVICES_LIST](context, key) {
        return OpenApiService.getServices(key)
            .then(({data}) => {
                context.commit(SET_SERVICES, data);
            })
    },
    [FETCH_COMMENTS](context, key) {
        return OpenApiService.getComments(key)
            .then(({data}) => {
                context.commit(SET_COMMENTS, data);
            })
    },
    [FETCH_COMMENTS_PAGES_COUNT](context, key) {
        return OpenApiService.getCommentPages(key)
            .then(({data}) => {
                context.commit(SET_COMMENTS_COUNT, data);
            })
    },
    [POST_COMMENT](context, comment) {
        return OpenApiService.post(comment)
            .then(({data}) => {
                context.commit(SET_COMMENT, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [SET_APPOINTMENT_DETAILS](state, appointmentDetails) {
        state.appointmentDetails = appointmentDetails;
        state.organization = appointmentDetails.organization;
    },
    [SET_COMPANY_DETAILS](state, response) {
        state.organization = response;
    },
    [SET_ACTIVE_STAFF](state, response) {
        state.activeStaff = response;
    },
    [SET_SERVICES](state, response) {
        state.services = response;
    },
    [SET_COMMENT](state, response) {
        state.errors = response.errors;
    },
    [SET_STAFF](state, response) {
        state.staff = response;
    },
    [SET_COMMENTS](state, response) {
        state.comments = response;
    },
    [SET_COMMENTS_COUNT](state, response) {
        state.commentPages = response;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
