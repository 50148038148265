import API_URL from "@/common/config";

export default {

    thumbnailImageUrlWithId(id) {
        return `${API_URL}/fs/file/${id}/thumbnail`
    },
    
    thumbnailImageUrlWithIdAndSize(id, size) {
        return `${API_URL}/fs/file/${id}/thumbnail/${size}`
    },

    imageUrlWithId(id) {
        return `${API_URL}/fs/file/${id}`
    },

    companyName() {
        return window.location.host.split('.')[0]
    }
    
}