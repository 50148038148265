import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n)


function loadLocaleMessages() {
    const locales = require.context(
        "../locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            if (locale === "ua") {
                messages["uk"] = locales(key);
            }
            messages[locale] = locales(key);
        }
    });
    return messages;
}

console.log("Initial language", navigator.language.split('-')[0])

export default new VueI18n({
    locale: navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
    messages: loadLocaleMessages()
})